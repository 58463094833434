@import "styles/abstracts/variables"




.lessonTime
  padding: 4rem
  width: 100%
  .header
    h1
      font-size: 2rem
      color: $color-grey-dark
      font-weight: 600
      margin-bottom: 2rem
  .container
    width: 100%
    overflow: auto
  .thTuron
    white-space: nowrap
    text-align: center
  .lessonTuron
    display: flex
    flex-direction: column
    gap: 1rem
    background-color: #001B61
    color: white
    white-space: nowrap
    padding: 1rem
    border-radius: 10px