

$padding-content: 3rem


.main
  width: 100%
  height: 100%
  background-color: white
  margin: 0 2rem
  border-radius: 5px
  overflow: hidden
  &.default
    .image
      display: none
    .content
      width: 100%
      height: 100%
      padding: $padding-content

  &.background
    position: relative

    .image
      width: 100%
      height: 100%
      display: block
      position: relative
      z-index: 2
      img
        width: 100%
        height: 100%
        object-fit: contain
    .content
      width: 100%
      height: 100%
      position: absolute
      z-index: 3
      background-color: rgba(0, 0, 0, 0.68)
      top: 0
      left: 0
      padding: $padding-content



  &.left
    display: flex
    .image
      width: 50%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      img
        width: 30rem
        height: 30rem
        object-fit: cover
    .content
      width: 50%
      height: 100%
      padding: $padding-content

  &.right
    display: flex
    .image
      width: 50%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      order: 2
      img
        width: 30rem
        height: 30rem
        object-fit: cover
    .content
      width: 50%
      height: 100%
      order: 1
      padding: $padding-content

  &.fullLeft
    display: flex
    .image
      min-width: 30%
      height: 100%
      display: flex
      align-items: center
      justify-content: center

      img
        width: 100%
        height: 100%
        object-fit: cover
    .content
      width: 100%
      height: 100%
      padding: $padding-content


  &.fullRight
    display: flex
    .image
      min-width: 30%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      order: 2
      img
        width: 100%
        height: 100%
        object-fit: cover
    .content
      width: 100%
      height: 100%
      order: 1
      padding: $padding-content

  &.top
    display: flex
    flex-direction: column
    .image
      width: 100%
      min-height: 30%
      display: flex
      align-items: center
      justify-content: center
      img
        width: 100%
        height: 100%
        object-fit: cover
    .content
      width: 100%
      height: 100%
      padding: $padding-content


  &.bottom
    display: flex
    flex-direction: column
    .image
      width: 100%
      min-height: 40%
      display: flex
      align-items: center
      justify-content: center
      order: 2
      img
        width: 100%
        height: 100%
        object-fit: cover
    .content
      width: 100%
      height: 100%
      order: 1
      padding: $padding-content

