@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.gifs
  width: 100%

  .header
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

  .list
    width: 100%
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 2rem
    height: 40rem
    overflow: auto
    margin-top: 2rem
    padding: 2rem

    &__item
      width: 20rem
      height: 20rem
      border: 1px solid $color-grey-light
      position: relative
      cursor: pointer
      transition: all .1s
      user-select: none
      @include btn-active
      &:before
        position: absolute
        display: block
        content: ""
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.58)
        opacity: 0
        visibility: hidden
        transition: all .2s
      &:hover
        &:before
          opacity: 1
          visibility: visible
      img
        width: 100%
        height: 100%