@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.lessonPlan
  padding: 3rem
  padding-bottom: 6rem

  .header
    display: flex
    align-items: flex-start
    justify-content: space-between

  .subheader
    display: flex
    align-items: flex-start
    justify-content: flex-start
    margin: 2rem 0
  .wrapper
    margin-top: 2rem
    display: flex
    flex-wrap: wrap
  .footer
    background-color: rgba(255, 255, 255, 0.76)
    bottom: 0
    left: 0
    position: fixed
    width: 100%
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 2rem
    z-index: 13


.row
  flex-direction: row !important
  justify-content: flex-start !important
  gap: 2rem
.students
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .student
    width: 30rem !important
  .item
    margin: 1.5rem 0
    border-radius: 5px
    padding: 2rem
    width: 80%
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 6px 14px 0 rgba(0, 0, 0, 0.10), 0 25px 25px 0 rgba(0, 0, 0, 0.09), 0 56px 33px 0 rgba(0, 0, 0, 0.05), 0 99px 40px 0 rgba(0, 0, 0, 0.01), 0 155px 43px 0 rgba(0, 0, 0, 0.00)
    background-color: white
    position: relative
    .top
      display: flex
      align-items: center
      .info
        flex-grow: 3
        margin-left: 1rem
        h1
          color: $color-grey-dark
          font-size: 1.7rem
      .arrow
        transform: rotate(-90deg)
        font-size: 2rem

      img
        border-radius: 50%
        width: 5rem
        height: 5rem
        object-fit: cover
    p
      margin-top: 1rem
      font-size: 1.8rem
    .inside
      height: 0
      overflow: hidden
      transition: all .2s
