

.text
  width: 100%
  height: fit-content
  margin: 1rem 0
  display: flex
  align-items: center
  justify-content: center
  &.smaller
    font-size: 1.6rem
    font-weight: normal
  &.small
    font-size: 2rem
    font-weight: normal
  &.normal
    font-size: 2.7rem
    font-weight: normal
  &.big
    font-size: 4.7rem
    font-weight: bold

  &.extraBig
    font-size: 6.7rem
    font-weight: bold