@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.sidebar
  overflow: auto
  height: calc(100% - 6rem)
  width: 100%
  padding: 2rem


  .separator
    width: 100%
    height: 1px
    background-color: rgba($color-grey-light, .7)
    margin: 3rem 0


  .type
    h2
      margin-bottom: 1.5rem
      font-weight: normal

    &__btn
      border-radius: 5px
      width: 100%
      padding: 1.5rem
      margin: 0
      display: flex
      align-items: center
      justify-content: center
      gap: 1rem

      svg
        width: 2rem
        fill: white

      i
        font-size: 2.5rem

  .heading
    width: 100%
    margin: 0
    padding: 0

    input
      height: 4rem !important

      &:focus
        outline: 1px solid $color-slide !important
        border: 1px solid $color-slide !important


  .subheading
    width: 100%
    padding: 0
    margin: 2rem 0

    textarea
      padding: 1rem

      &:focus
        outline: 1px solid $color-slide !important
        border: 1px solid $color-slide !important



  .variants
    width: 100%
    margin: 2rem 0

    h2
      font-weight: normal
      font-size: 1.7rem
      margin-bottom: 1rem
    &__wrapper
      display: flex
      flex-direction: column
      align-items: flex-start
      .radio
        font-size: 1.6rem

  .videoUrl
    display: flex
    align-items: flex-end
    justify-content: center
    margin: 2rem 0
    gap: 1rem
    label
      padding: 0
      margin: 0
