@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.addition
  width: 100%
  padding: 3rem
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  &__item
    margin: 2rem
    &:hover
      .icon
        box-shadow: 0 0 0 0 rgba(0, 178, 255, 0.10), 0 2px 5px 0 rgba(0, 178, 255, 0.10), 0 10px 10px 0 rgba(0, 178, 255, 0.09), 0 22px 13px 0 rgba(0, 178, 255, 0.05), 0 40px 16px 0 rgba(0, 178, 255, 0.01), 0 62px 17px 0 rgba(0, 178, 255, 0.00)
        svg
          fill: $color-main
        i
          color: $color-main

    .icon
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      width: 12rem
      height: 12rem
      border-radius: 10px
      box-shadow: 0 0 0 0 rgba(92, 0, 0, 0.10), 0 1px 3px 0 rgba(92, 0, 0, 0.10), 0 5px 5px 0 rgba(92, 0, 0, 0.09), 0 10px 6px 0 rgba(92, 0, 0, 0.05), 0 18px 7px 0 rgba(92, 0, 0, 0.01), 0 29px 8px 0 rgba(92, 0, 0, 0.00)
      transition: all .1s
      @include btn-active
      svg
        width: 5rem
        height: 5rem
        //color:
        fill: $color-grey-light
        transition: all .1s
      i
        transition: all .1s
        color: $color-grey-light
        font-size: 5rem
    .info
      margin-top: 1.5rem
      font-size: 1.7rem
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      transition: all .1s

@media only screen and (max-width: 425px)
  .addition
    justify-content: space-around
    padding: 0
    &__item
      width: 13rem
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      &:hover
        .icon
          box-shadow: 0 0 0 0 rgba(0, 178, 255, 0.10), 0 2px 5px 0 rgba(0, 178, 255, 0.10), 0 10px 10px 0 rgba(0, 178, 255, 0.09), 0 22px 13px 0 rgba(0, 178, 255, 0.05), 0 40px 16px 0 rgba(0, 178, 255, 0.01), 0 62px 17px 0 rgba(0, 178, 255, 0.00)
          svg
            fill: $color-main
          i
            color: $color-main

      .icon
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        width: 8rem
        height: 8rem
        border-radius: 10px
        box-shadow: 0 0 0 0 rgba(92, 0, 0, 0.10), 0 1px 3px 0 rgba(92, 0, 0, 0.10), 0 5px 5px 0 rgba(92, 0, 0, 0.09), 0 10px 6px 0 rgba(92, 0, 0, 0.05), 0 18px 7px 0 rgba(92, 0, 0, 0.01), 0 29px 8px 0 rgba(92, 0, 0, 0.00)
        transition: all .1s
        @include btn-active
        svg
          width: 4.5rem
          height: 4.5rem
          //color:
          fill: $color-grey-light
          transition: all .1s
        i
          transition: all .1s
          color: $color-grey-light
          font-size: 4rem
      .info

        font-size: 1.6rem


@media only screen and (max-width: 375px)
  .addition
    justify-content: space-evenly
    padding: 0
    &__item
      width: 13rem


      .icon

        width: 8rem
        height: 8rem

        svg
          width: 3rem
          height: 3rem
        i

          font-size: 4rem

