@import "styles/abstracts/variables"


.textareaLabel
  display: block
  width: 32rem
  padding: 1rem
  .info
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    span
      font-weight: 600
      font-size: 1.5rem
      margin-bottom: 1rem
  .textarea
    border: 1px solid $color-grey-dark
    outline: none
    font-size: 1.5rem
    padding: 2px 5px
    width: 100%
    height: 10rem
    border-radius: 3px
    resize: none
    &:focus
      outline: 1px solid $color-grey-dark
    &.error
      border: none
      outline: 2px solid  #f15c5c
  .message
    position: relative
    margin-top: 1rem
    &__error
      font-size: 1.5rem
      color: #ff3737

