@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"


.form
  width: 100%
  //display: flex
  //flex-direction: column
  //align-items: center
  padding-bottom: 1rem
  .submit
    margin-top: 2rem
    width: fit-content
    height: fit-content
    padding: 0.5rem 2rem
    outline: none
    font-size: 1.7rem
    cursor: pointer
    transition: all .2s
    border: 2px solid $color-main-dark
    background-color: white
    color: $color-grey-dark
    &:hover,&:focus
      background-color: $color-main-dark
      color: white
    @include btn-active