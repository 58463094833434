@import "styles/abstracts/variables"


.label
  width: 100%

  & .info
    display: flex
    align-items: center
    justify-content: space-between

    h2
      font-weight: normal
      font-size: 1.7rem

  .input
    width: 100%
    padding: 0
    margin: 1rem 0

    input
      &:focus
        outline: 1px solid $color-slide !important
        border: 1px solid $color-slide !important
