@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"




.typesPreview
  width: 90%
  height: 90%
  padding: 2rem
  display: flex
  gap: 2rem
  .preview
    width: 60%
    height: 100%
    background-color: white
    border-radius: 10px
  .types
    width: 40%
    height: 100%
    overflow: auto
    background-color: white
    border-radius: 10px
    padding: 2rem
    &__wrapper
      width: 100%

      h1
        font-size: 2rem
        font-weight: bold
      .items
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: flex-start
        gap: 2rem
        padding: 1rem
        margin-top: 2rem
        &__item
          display: flex
          flex-direction: column
          align-items: center
          .box
            width: 13rem
            height: 13rem
            border-radius: 10px
            background-color: rgba($color-grey-dark, .8)
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            transition: all .1s
            &:hover
              background-color: $color-slide
              translate: 0 -1rem
              box-shadow: 0px 32px 9px 0px rgba(0, 163, 255, 1%), 0px 21px 8px 0px rgba(0, 163, 255, 5%), 0px 12px 7px 0px rgba(0, 163, 255, 18%), 0px 5px 5px 0px rgba(0, 163, 255, 30%), 0px 1px 3px 0px rgba(0, 163, 255, 34%)
            svg
              width: 4rem
              height: 4rem
              fill: white
              stroke: white
          h2
            margin-top: 1rem
            font-weight: 600