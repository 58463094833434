@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.back
  padding: .6rem 2rem
  width: fit-content
  height: fit-content
  background-color: $color-grey-light
  border-radius: 5px
  font-size: 1.6rem
  color: white
  cursor: pointer
  transition: all .1s
  @include btn-active
  margin-bottom: 4rem
  &:hover
    background-color: $color-main-light







