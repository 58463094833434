@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"


.presentations
  padding: 2rem
  display: flex
  width: 100%

  .menu
    width: 23rem
    height: calc(100dvh - #{$appHeader-height-xl + 4rem})
    border-right: 1px solid #E9E9E9
    display: flex
    flex-direction: column
    gap: 1rem
    padding: 2rem

    &__item
      width: 100%
      color: $color-grey-dark
      font-size: 1.8rem

      &.active
        color: $color-main

  main
    width: 100%
    padding: 2rem



.workspace
  display: flex
  flex-direction: column
  gap: 2rem
  .header
    flex-grow: 1
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center


  .subheader
    width: 100%
    display: flex
    align-items: flex-end
    flex-grow: 1


    .search
      max-width: 30rem
      width: 50rem !important
      height: 4rem

  .wrapper
    height: 60rem
    flex-grow: 2
    overflow: auto
    position: relative

    &::-webkit-scrollbar
      width: 8px

    &::-webkit-scrollbar-track
      background-color: #ffffff

    &::-webkit-scrollbar-thumb
      cursor: pointer
      background-color: #313131

    //.table
    //  border-collapse: separate
    //  border-spacing: 0 0px
    //  thead
    //    tr
    //      position: sticky
    //      top: 0
    //      background-color: white
    //  tbody
    //
    //    tr
    //      border: none
    //      background-color: white
    //      border-radius: 10px
    //      cursor: pointer
    //
    //      &:hover
    //        background-color: #eeeeee
    //
    //    td
    //      color: black
    //
    //      padding: 2rem 1rem
    //
    //      &:first-child
    //        border-radius: 10px 0 0 10px
    //
    //      &:last-child
    //        border-radius: 0 10px 10px 0
    .table
      width: 100%
      height: 50rem
      display: flex
      flex-direction: column
      &__parent
        &>div
          font-weight: 700
      &__child
        border-radius: 10px
        &:hover
          background-color: #eeeeee
      &__item
        width: 100%
        display: flex
        gap: 1rem
        padding: 2rem 1rem
        font-size: 1.8rem




        .item
          flex-grow: 1
          display: flex
          color: $color-black !important
          gap: 1rem
        .ds_no
          opacity: 0
          visibility: hidden
        .subItem
          flex: 1 1 auto
        .w20
          width: 30rem
          flex: 0 1 auto
        .w30
          width: 30%


    .popup
      width: 5rem
      //display: flex
      //justify-content: flex-end
      font-size: 2rem
      padding:  0 1rem
      cursor: pointer
      transition: all .5s
      @include btn-active


.filters
  width: 100%
  display: flex
  flex-direction: column
  gap: 1rem


//.popup__item
//  display: flex
//  gap: 1rem
//  align-items: center
//  cursor: pointer
//  background-color: #2b2b2b