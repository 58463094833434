@import "styles/abstracts/variables"


.inputLabel
  display: block
  max-width: 32rem
  min-width: 15rem
  padding: 0 1rem

  .info
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    span
      font-weight: 600
      font-size: 1.5rem
      margin-bottom: 1rem
  .field
    width: 100%
    display: flex
    align-items: center
    position: relative
    .input
      border: 1px solid $color-grey-dark
      outline: none
      font-size: 1.6rem
      padding: 2px 10px
      width: 100%
      height: 3.5rem
      border-radius: 3px
      &:focus
        outline: 1px solid $color-grey-dark
      &.error
        border: none
        outline: 2px solid  #f15c5c
    .eye
      font-size: 2rem
      @media only screen and (max-width: 430px)
        font-size: 1.8rem
  .message
    position: relative
    margin-top: 1rem
    &__error
      font-size: 1.5rem
      color: #ff3737

