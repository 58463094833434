@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"


.imageBtn
  max-width: 25rem
  min-width: 22rem
  display: flex
  align-items: center
  background-color: rgba($color-grey-light, .1)
  padding: 2rem
  gap: 2rem
  border-radius: 5px
  border: 1px solid rgba($color-grey-light, .1)
  svg
    width: 4rem
    height: 4rem
    flex-shrink: 0


.imageModal
  width: 100rem
  min-height: 60rem
  background-color: white
  border-radius: 5px
  padding: 3rem
  h1
    font-weight: normal
  .header
    margin-top: 2rem
    display: flex
    align-items: center
    &__item
      min-width: 10rem
      padding: 1rem 0
      border-bottom: 2px solid white
      font-size: 1.8rem
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      &.active
        border-bottom: 2px solid $color-main



