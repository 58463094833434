@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.result
  width: 100%
  height: 90vh
  display: flex
  align-items: center
  justify-content: center
  &__box
    width: 50rem
    max-height: 50rem
    box-shadow: 0 0 8px 1px $color-grey-dark
    border-radius: 10px
    padding: 2rem
  .info
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 2rem
    flex-wrap: wrap
    margin: 2rem 0
    hr
      width: 100%
      height: 2px solid $color-grey-dark
    h1
      font-size: 1.5rem
      //font-weight: normal
    h2
      font-weight: normal
      font-size: 1.6rem
