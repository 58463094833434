@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.exc
  width: 60rem

  &__item
    width: fit-content
    padding: .7rem 2rem
    border: 2px solid $color-grey-light
    font-size: 1.6rem
    cursor: pointer
    margin-right: 2rem
    margin-top: 1rem
    &.active
      background-color: $color-grey-light
      color: white
      border: 2px solid $color-grey-dark
    &:hover
      background-color: $color-grey-light
      color: white
      user-select: none
      transition: all .1s
      @include btn-active
  .header
    width: 100%
    display: flex
    align-items: center
    justify-content: flex-start
    position: sticky
    top: 0
    padding: 1rem 0
    background-color: white

  .footer
    padding: 1rem 0
    width: 100%
    position: sticky
    bottom: 0
    background-color: white
  .container
    width: 100%
    padding: 1rem
    display: flex
    flex-wrap: wrap
    align-items: center
  &__view
    width: 100%
    position: relative
    margin: 2rem 0
    .line
      //border-top: 1px solid rgba($color-grey-light, .3)
      //border-bottom: 1px solid rgba($color-grey-light, .3)
      margin: 2rem 0
    .edit
      top: 0
      right: -3rem
      position: absolute
      font-size: 1.8rem
      cursor: pointer
      color: red
      transition: all .1s
      @include btn-active
    .container
      width: 100%
      padding: 2rem
      border-radius: 5px
      border: 1px solid rgba($color-grey-dark,.4)
      //border: 2px solid rgba($color-success,.7)
      //box-shadow:  0 0 20px 1px rgba($color-success,.5)
      margin-bottom: 2rem
      position: relative
      .subheader
        width: 100%
        display: flex
        align-items: center
        justify-content: flex-end
        position: sticky
        top: 3rem
        padding: 1rem 0



@media only screen and (max-width: 1024px)
  .exc
    width: 100%
    &__view
      width: 100%
      position: relative
      margin: 2rem 0
      .edit
        top: 0
        right: -3rem
        position: absolute
        font-size: 1.8rem
        cursor: pointer
        color: red
        transition: all .1s
        @include btn-active
      .container
        width: 100%
        padding: 2rem
        border-radius: 5px
        border: 1px solid rgba($color-grey-dark,.4)
        margin-bottom: 2rem
        position: relative