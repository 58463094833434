


@mixin btn-active
  transform: scale(1)
  &:active
    transform: scale(0.9)






