@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"




.group
  width: 100%
  position: relative
  .header
    width: 100%
    height: 30rem
    position: relative
    .info
      display: flex
      align-items: flex-start
      justify-content: flex-end
      padding: 4rem
      flex-direction: column
      top: 0
      width: 100%
      height: 100%
      position: absolute
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.67) 0%, rgba(209, 209, 209, 0.24) 100%)
      h1
        color: white
        font-size: 3rem
        font-weight: 700
        &:last-child
          margin-top: 2rem
          font-size: 2rem
          font-weight: 400
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: bottom

  .subHeader
    width: 100%
    .newBack
      top: 2rem
      left: 2rem
      position: absolute
      margin: 0
      background-color: white
      i
        color: $color-grey-dark
  .navigation
    margin-top: 2rem
    width: 100%
    padding: 1rem
    display: flex
    align-items: center
    justify-content: center
    .item
      margin: 0 1rem
      color: $color-grey-light
      font-size: 1.8rem
      border-bottom: 2px solid white
      font-weight: 600
      display: flex
      align-items: center
      justify-content: center
      span
        margin-left: 1rem
      svg
        width: 2.5rem
        path,line,rect
          stroke: $color-grey-light
        span
          color: $color-main
      &.active
        svg
          path,line,rect
            stroke: $color-main
        span
          color: $color-main



@media only screen and (max-width: 1024px)
  .group
    width: 100%
    .header
      width: 100%
      height: 25rem
      position: relative
      .info
        padding: 3rem
        h1
          font-size: 2.7rem
          &:last-child
            font-size: 1.8rem

    .navigation
      .item
        font-size: 1.7rem


@media only screen and (max-width: 768px)
  .group
    width: 100%
    .header
      width: 100%
      height: 25rem
      position: relative
      .info
        padding: 3rem
        h1
          font-size: 2.7rem
          &:last-child
            font-size: 1.8rem

    .navigation
      .item
        font-size: 1.7rem


@media only screen and (max-width: 768px)
  .group
    width: 100%
    .header
      width: 100%
      height: 23rem
      position: relative
      .info
        padding: 3rem
        h1
          font-size: 2.5rem
          &:last-child
            margin-top: 1.5rem
            font-size: 1.8rem

    .navigation
      .item
        font-size: 1.7rem


@media only screen and (max-width: 430px)
  .group
    width: 100%
    .header
      width: 100%
      height: 20rem
      position: relative
      .info
        padding: 3rem
        h1
          font-size: 2.3rem
          &:last-child
            margin-top: 1.5rem
            font-size: 1.7rem

    .navigation
      align-items: center
      justify-content: space-around
      margin: 0
      .item
        width: 33%
        margin: 0
        flex-direction: column
        font-size: 1.6rem
        border-bottom: 2px solid white
        svg
          width: 2rem
        span
          display: none

        &.active
          border-bottom: 2px solid $color-main
