@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.popup
  position: relative
  width: fit-content
  height: fit-content
  &__modal
    max-height: 50rem
    position: fixed
    z-index: 999
    transition: opacity .5s, visibility .5s
    left: 0
    top: 0
    pointer-events: auto
    animation: animate linear .1s

    .arrow
      width: 0
      height: 0
      border-top: 10px solid transparent
      border-bottom: 10px solid transparent
      position: absolute
      top: 0
      left: 0
      &.left
        border-right: 9px solid white
      &.right
        border-left: 9px solid white
    &.options
      width: 15rem
      display: flex
      flex-direction: column
      box-shadow: 0 76px 30px rgba(92, 0, 0, 0.01), 0 43px 26px rgba(92, 0, 0, 0.05), 0 19px 19px rgba(92, 0, 0, 0.09), 0 5px 10px rgba(92, 0, 0, 0.1), 0 0 0 rgba(92, 0, 0, 0.1)
      border-radius: 5px
      background-color: white
      .item
        padding: 1rem 2rem
        display: flex
        align-items: flex-start
        font-size: 1.8rem
        color: $color-grey-dark
        gap: 1rem
        cursor: pointer
        border-radius: 5px
        transition: background-color .1s
        user-select: none
        &:hover
          background-color: #eeeeee
          @include btn-active
        span
          font-size: 1.7rem
        i
          width: 3rem
          font-size: 1.8rem
          //color: $color-grey-dark


@keyframes animate
  from
    opacity: 0
    visibility: hidden
  to
    visibility: visible
    opacity: 1