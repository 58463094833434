@import "styles/abstracts/variables"

.bookPurchases
  width: 100%
  padding: 3rem

  .header
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    margin-bottom: 2rem
    .back
      width: fit-content
      margin: 0


    &>div
      width: 100%
      display: flex
      flex-wrap: wrap
      gap: 1rem
  .wrapper
    width: 100%
    overflow: auto

  .checkbox
    width: fit-content
  .filterIcon
    padding: 1rem
    font-size: 2.5rem
    color: $color-grey-dark
