@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"


.presentation
  background-color: #E8E8E8
  height: calc(100dvh - #{$appHeader-height-xl})

  .header
    width: 100%
    background-color: white
    height: 8rem
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 2rem
    &__item
      display: flex
      align-items: center
      justify-content: center
      gap: 2rem
      .back
        cursor: pointer
        font-size: 2.5rem
      .icon
        cursor: pointer
        width: 4.3rem
        height: 4.3rem
        border: 1px solid rgba($color-grey-dark, .5)
        color: $color-grey-dark
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        font-size: 1.9rem
        transition: all .1s
        @include btn-active
        &:hover
          background-color: $color-grey-dark
          color: white
      .title
        margin-left: 2rem
        border-right: 1px solid rgba($color-grey-light, .6)
        padding-right: 1rem
        h1,h3
          font-weight: 600

  .wrapper
    padding: 2rem 0
    width: 100%
    height: calc(100% - 8rem)
    display: flex
    .sidebar__left
      min-width: 20rem
      height: 100%
      display: flex
      flex-direction: column
      align-items: center

      .tabs
        width: 100%
        display: flex
        flex-direction: column
        margin: 2rem 0
        z-index: 2
        gap: 1rem
        overflow: auto
        &::-webkit-scrollbar
          width: 8px
        &::-webkit-scrollbar-track
          background-color: #ffffff
        &::-webkit-scrollbar-thumb
          cursor: pointer
          background-color: #313131
        &__item
          width: 100%
          display: flex
          align-items: center
          border-left: 5px solid #E8E8E8
          cursor: pointer
          user-select: none
          position: relative
          &:active
            .info
              transform: scale(.9)
          &.active
            border-left: 5px solid $color-slide
            .info
              border: 1px solid $color-slide
          .num
            width: 3rem
            display: flex
            align-items: center
            justify-content: center
            color: $color-grey-dark
            font-size: 1.8rem
          .info
            width: 13.5rem
            //height: 8rem
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            padding: 1rem
            background-color: white
            border-radius: 5px
            gap: 1rem
            border: 1px solid #E8E8E8
            transition: all .1s

            .tooltip
              width: 15rem
              max-height: 7rem
              background-color: $color-grey-dark
              padding: 1rem
              font-size: 1.6rem
              position: absolute

            svg
              width: 3rem
            h1
              white-space: nowrap
              overflow: hidden
              width: 100%
              font-weight: normal
              font-size: 1.7rem
              //text-indent: each-line
              //text-overflow: clip
              text-align: center
              text-overflow: ellipsis


