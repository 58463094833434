@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

//.table
//  width: 100%
//  padding: 1rem
//  border-collapse: collapse
//  thead
//    tr
//      width: 100%
//      background-color: #65bdf8
//    th
//      margin: 0
//      border: none
//      outline: none
//      padding: 1rem
//      font-size: 1.7rem
//      text-align: left
//      color: white
//      &:first-child
//        width: 6rem
//      &:last-child
//        width: 15rem
//
//  tbody
//    tr
//      width: 100%
//      background-color: #f8fbff
//      transition: all .1s
//      &:nth-child(2n)
//        background-color: #eff6ff
//      &:hover
//        background-color: #daedff
//
//    td
//      width: fit-content
//      padding: 1.5rem
//      font-size: 1.6rem
//      color: $color-grey-dark
//      i
//        cursor: pointer
//        font-size: 2rem
//        margin: 0 1rem
//        transition: all .1s
//        color: $color-grey-dark
//        @include btn-active
//



.table
  width: 100%
  padding: 1rem
  border-collapse: collapse
  thead
    tr
      width: 100%
      border-bottom: 2px solid  #CFCFCF
    th
      min-width: 10rem
      margin: 0
      border: none
      outline: none
      padding: 1rem
      font-size: 1.7rem
      text-align: left
      color: $color-grey-dark

      &:first-child
        min-width: 5rem
        width: 5rem
      //&:last-child
      //  width: 15rem

  tbody


    tr
      width: 100%
      background-color: #f8fbff
      transition: all .1s
      border-bottom: 2px solid #CFCFCF
      //&:nth-child(2n)
      //  background-color: #eff6ff
      &:hover
        background-color: #daedff

    td
      width: fit-content
      padding: 1.5rem
      font-size: 1.6rem
      color: $color-grey-dark
      i
        cursor: pointer
        font-size: 2rem
        margin: 0 1rem
        transition: all .1s
        color: $color-grey-dark
        @include btn-active





@media only screen and (max-width: 1024px)
  .table
    thead
      th
        font-size: 1.6rem




@media only screen and (max-width: 768px)
  .table
    position: relative
    //height: 100%
    thead
      tr
        position: sticky
        top: 0
        background-color: white
        z-index: 10
      th
        margin: 0
        border: none
        outline: none
        padding: 1rem
        font-size: 1.6rem
        text-align: left
        color: $color-grey-dark
        &:first-child
          width: 6rem
          position: sticky
          left: 0
          background-color: white
        &:last-child
          width: 15rem
    tbody
      tr
        width: 100%
        background-color: #f8fbff
        transition: all .1s
        border-bottom: 2px solid #CFCFCF
        &:hover
          background-color: #daedff
          td
            background-color: #daedff

      td
        width: fit-content
        padding: 1.5rem
        font-size: 1.6rem
        color: $color-grey-dark
        transition: all .1s
        &:first-child
          position: sticky
          left: 0
          background-color: rgb(255, 255, 255)
        i
          cursor: pointer
          font-size: 2rem
          margin: 0 1rem
          transition: all .1s
          color: $color-grey-dark
          @include btn-active