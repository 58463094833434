

@keyframes ldio-ow3cspdrzfh
  0%
    transform: translate(-50%,-50%) rotate(0deg)
  100%
    transform: translate(-50%,-50%) rotate(360deg)

.loader
  width: 100%
  height: 100vh
  background-color: rgba(0, 0, 0, 0.36)
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 100000
  top: 0
  left: 0
  right: 0
  bottom: 0
  transition: all .2s
  .loader__wrapper
    & > div
      position: absolute
      width: 40px
      height: 40px
      border: 3px solid #ffffff
      border-top-color: transparent
      border-radius: 50%

  .loader__wrapper
    &> div
      animation: ldio-ow3cspdrzfh 1s linear infinite
      top: 100px
      left: 100px

  .spinner
    width: 200px
    height: 200px
    display: inline-block
    overflow: hidden


  .loader__wrapper
    width: 100%
    height: 100%
    position: relative
    transform: translateZ(0) scale(1)
    backface-visibility: hidden
    transform-origin: 0 0

  .loader__wrapper > div
    box-sizing: content-box