@import "styles/abstracts/mixins"
@import "styles/abstracts/variables"

.alertWrapper
  top: 2rem
  position: absolute
  left: 50%
  transform: translateX(-50%)
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  z-index: 2002
  .alert
    width: 35rem
    padding: 1rem 2rem
    box-shadow: 0 76px 30px rgba(92, 0, 0, 0.01), 0 43px 26px rgba(92, 0, 0, 0.05), 0 19px 19px rgba(92, 0, 0, 0.09), 0 5px 10px rgba(92, 0, 0, 0.1), 0 0 0 rgba(92, 0, 0, 0.1)
    background-color: white
    border-radius: 10px
    display: flex
    align-items: flex-start
    justify-content: space-between
    opacity: 0
    visibility: hidden
    transition: all .2s
    margin: 1rem 0
    &.active
      opacity: 1
      visibility: visible
    &.success
      border-bottom: 2px solid $color-success
    &.danger
      border-bottom: 2px solid $color-danger
    &.warning
      border-bottom: 2px solid $color-warning
    .text
      font-size: 1.6rem
    .close
      font-size: 2.3rem
      color: $color-danger
      margin-left: 2rem
      cursor: pointer
      transition: all .1s
      @include btn-active

@media only screen and (max-width: 425px)
  .alert
    width: 25rem
    padding: 1rem 2rem
    box-shadow: 0 76px 30px rgba(92, 0, 0, 0.01), 0 43px 26px rgba(92, 0, 0, 0.05), 0 19px 19px rgba(92, 0, 0, 0.09), 0 5px 10px rgba(92, 0, 0, 0.1), 0 0 0 rgba(92, 0, 0, 0.1)
    background-color: white
    border-radius: 10px
    display: flex
    align-items: flex-start
    justify-content: space-between
    opacity: 0
    visibility: hidden
    transition: all .2s
    margin: 1rem 0
