@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.createText
  width: 100%
  .subHeader
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 1rem
    .trash
      font-size: 1.7rem
      color: red
      cursor: pointer
      @include btn-active


.viewText
  margin-top: 1rem
  width: 100%
  pre
    border-radius: 5px
    background-color: #f6f6f6
    padding: 1rem
    font-size: 1.8rem
  .text
    position: relative
    width: fit-content
    margin-bottom: 1rem
    p
      font-size: 1.8rem
    .popup
      //visibility: hidden
      //opacity: 0
      transition-delay: 1s
      top: -10px
      right: -25px
      position: absolute
      transition: all .1s
      i
        font-size: 1.7rem
