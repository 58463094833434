$color-main: #00B2FF
$color-main-dark: #0088c0
$color-main-light: #1ebaff

$color-grey-dark: #424242
$color-grey-light: #797979
$color-grey-lighter: #e3e3e3
$color-white: #fff
$color-black: #000000
$color-danger: #ff1919
$color-warning: #ff8c19
$color-success: #3fb730
$color-slide: #00A3FF

// fonts
$font-size: 1.6rem


// components

// height
$appHeader-height-xl: 6rem
$appHeader-height-lg: 5.5rem
$appHeader-height-md: 5rem
$appHeader-height-sm: 4.7rem
$appHeader-height-xs: 4rem


$appMenuBar-width: 12rem

