@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.dropImage
  margin-top: 3rem
  width: 100%
  height: 40rem
  border: 2px dashed $color-grey-light
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 1rem
  cursor: pointer
  &:hover
    border: 2px dashed $color-main-light
    *
      transition: all .1s
    svg
      fill: $color-main

  svg
    width: 15rem
    height: 15rem
  h2,h3
    font-weight: normal
  h2
    font-size: 1.7rem
  h3
    font-size: 1.6rem