@import "styles/abstracts/variables"

.selectLabel
  display: block
  min-width: 8rem
  padding: 0 1rem
  .info
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    span
      font-weight: 500
      font-size: 1.5rem
      margin-bottom: 1rem
  .select
    border: 1px solid $color-grey-dark
    outline: none
    font-size: 1.5rem
    padding: 2px 10px
    max-width: fit-content
    min-width: 100%
    height: 3.5rem
    border-radius: 3px


    &:focus
      outline: 1px solid $color-grey-dark
    &.error
      border: none
      outline: 2px solid  #f15c5c

  .message
    position: relative
    margin-top: 1rem
    &__error
      font-size: 1.5rem
      color: #ff3737










