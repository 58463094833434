
.overlay
  width: 100%
  height: 100vh
  z-index: 2002
  opacity: 0
  visibility: hidden
  transition: all .1s
  display: flex
  justify-content: center
  padding: 2rem
  position: fixed
  top: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.61)

  &.active
    opacity: 1
    visibility: visible
.confirm
  width: 50rem
  padding: 2rem
  box-shadow: 0 76px 30px rgba(0, 0, 0, 0.01), 0 43px 26px rgba(0, 0, 0, 0.05), 0 19px 19px rgba(0, 0, 0, 0.09), 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 rgba(92, 0, 0, 0.1)
  background-color: white
  height: fit-content
  border-radius: 5px
  .content
    margin-bottom: 1rem
    font-size: 1.6rem

  .footer
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100%
