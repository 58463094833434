


.label
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  .checkbox
    width: 2.3rem
    height: 2.3rem





