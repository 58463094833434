@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.crop

  &__wrapper
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .image
      width: 100%
      height: 40rem
      background-color: rgba($color-grey-light, .3)
      border-radius: 5px
      display: flex
      align-items: center
      justify-content: center

      img
        width: 100%
        height: 40rem
    .types
      display: flex
      align-items: center
      justify-content: center
      gap: 2rem
      margin-top: 2rem
      &__item
        padding: 1rem
        border: 1px solid $color-grey-light
        border-radius: 5px
        font-size: 1.6rem
        font-weight: 600
        cursor: pointer
        transition: all .1s
        @include btn-active
        &.active
          border: 1px solid $color-slide
          color: $color-slide
  .subheader
    width: 100%
    margin-top: 2rem
    display: flex
    align-items: center
    justify-content: space-between