



.createTest
  width: 100%
  padding: 3rem
  .header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 2rem

  .icon
    width: 15rem



.createForm
  display: flex
  flex-direction: column
  gap: 2rem