@import "styles/abstracts/mixins"

.books
  padding: 3rem
  .header
    margin-bottom: 2rem
    display: flex
    align-items: center
    justify-content: space-between
    @media only screen and (max-width: 768px)
      gap: 2rem
      flex-wrap: wrap
  .wrapper
    padding: 1rem
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    @media only screen and (max-width: 768px)
      justify-content: space-around
    .book
      width: 28rem
      height: 35rem
      border-radius: 10px
      overflow: hidden
      position: relative
      cursor: pointer
      transition: all .1s
      box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.10), 0 24px 24px 0 rgba(0, 0, 0, 0.09), 0 55px 33px 0 rgba(0, 0, 0, 0.05), 0 98px 39px 0 rgba(0, 0, 0, 0.01), 0 153px 43px 0 rgba(0, 0, 0, 0.00)
      margin: 1rem
      @media only screen and (max-width: 430px)
        width: 25rem
        height: 32rem
      &:active
        @include btn-active
      .info
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.68) 1.03%, rgba(0, 0, 0, 0.00) 100%)
        z-index: 2
        display: flex
        flex-direction: column
        justify-content: flex-end
        padding: 2rem
        color: white
        h2
          font-size: 2.2rem
          font-style: normal
          font-weight: 700
          margin-bottom: 1rem
        h3
          font-size: 1.8rem
          font-style: normal
          font-weight: 700
          margin-bottom: .7rem
      img
        width: 100%
        height: 100%
        object-fit: contain