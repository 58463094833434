@import "styles/abstracts/variables"


.editor
  width: 100%
  border: 1px solid $color-grey-light
  padding: 1rem
  border-radius: 10px



.other
  h2
    font-size: 18px
    color: #444
    margin-bottom: 7px
  a
    color: #777
    text-decoration: underline
    font-size: 14px
  ul
    padding: 0
    margin: 0
    list-style-type: none




h1
  font-size: 24px
  color: #333


.ltr
  text-align: left


.rtl
  text-align: right


.editor-container
  width: 100%
  color: #000
  position: relative
  line-height: 20px
  font-weight: 400
  text-align: left
  border-top-left-radius: 10px
  border-top-right-radius: 10px


.editor-inner
  background: #fff
  position: relative


.editor-input
  min-height: 150px
  resize: none
  font-size: 15px
  caret-color: rgb(5, 5, 5)
  position: relative
  tab-size: 1
  outline: 0
  padding: 15px 10px
  caret-color: #444


.editor-placeholder
  color: #999
  overflow: hidden
  position: absolute
  text-overflow: ellipsis
  top: 15px
  left: 10px
  font-size: 15px
  user-select: none
  display: inline-block
  pointer-events: none


.editor-text-bold
  font-weight: bold


.editor-text-italic
  font-style: italic


.editor-text-underline
  text-decoration: underline


.editor-text-strikethrough
  text-decoration: line-through

.editor-text-underlineStrikethrough
  text-decoration: underline line-through


.editor-text-code
  background-color: rgb(240, 242, 245)
  padding: 1px 0.25rem
  font-family: Menlo, Consolas, Monaco, monospace
  font-size: 94%


.editor-link
  color: rgb(33, 111, 219)
  text-decoration: none


.tree-view-output
  display: block
  background: #222
  color: #fff
  padding: 5px
  font-size: 12px
  white-space: pre-wrap
  margin: 1px auto 10px auto
  max-height: 250px
  position: relative
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  overflow: auto
  line-height: 14px


.editor-code
  background-color: rgb(240, 242, 245)
  font-family: Menlo, Consolas, Monaco, monospace
  display: block
  padding: 8px 8px 8px 52px
  line-height: 1.53
  font-size: 13px
  margin: 0
  margin-top: 8px
  margin-bottom: 8px
  tab-size: 2
  /* white-space: pre; */
  overflow-x: auto
  position: relative
  &:before
    content: attr(data-gutter)
    position: absolute
    background-color: #eee
    left: 0
    top: 0
    border-right: 1px solid #ccc
    padding: 8px
    color: #777
    white-space: pre-wrap
    text-align: right
    min-width: 25px

  &:after
    content: attr(data-highlight-language)
    top: 0
    right: 3px
    padding: 3px
    font-size: 10px
    text-transform: uppercase
    position: absolute
    color: rgba(0, 0, 0, 0.5)


.editor-tokenComment
  color: slategray


.editor-tokenPunctuation
  color: #999


.editor-tokenProperty
  color: #905


.editor-tokenSelector
  color: #690


.editor-tokenOperator
  color: #9a6e3a


.editor-tokenAttr
  color: #07a


.editor-tokenVariable
  color: #e90


.editor-tokenFunction
  color: #dd4a68


.editor-paragraph
  margin: 0
  margin-bottom: 8px
  position: relative


  &:last-child
    margin-bottom: 0


.editor-heading-h1
  font-size: 24px
  color: rgb(5, 5, 5)
  font-weight: 400
  margin: 0
  margin-bottom: 12px
  padding: 0


.editor-heading-h2
  font-size: 15px
  color: rgb(101, 103, 107)
  font-weight: 700
  margin: 0
  margin-top: 10px
  padding: 0
  text-transform: uppercase


.editor-quote
  margin: 0
  margin-left: 20px
  font-size: 15px
  color: rgb(101, 103, 107)
  border-left-color: rgb(206, 208, 212)
  border-left-width: 4px
  border-left-style: solid
  padding-left: 16px


.editor-list-ol
  padding: 0
  margin: 0
  margin-left: 16px


.editor-list-ul
  padding: 0
  margin: 0
  margin-left: 16px


.editor-listitem
  margin: 8px 32px 8px 32px


.editor-nested-listitem
  list-style-type: none


pre::-webkit-scrollbar
  background: transparent
  width: 10px


pre::-webkit-scrollbar-thumb
  background: #999



.toolbar
  display: flex
  margin-bottom: 1px
  background: #fff
  padding: 4px
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  vertical-align: middle
  & button.toolbar-item
    border: 0
    display: flex
    align-items: center
    background: none
    border-radius: 10px
    padding: 8px
    cursor: pointer
    vertical-align: middle
    &:hover:not([disabled])
      background-color: #eee
    &.active
      background-color: rgba(223, 232, 250, 0.3)
      i
        opacity: 1
    & i.format
      background-size: contain
      height: 18px
      width: 18px
      margin-top: 2px
      vertical-align: -0.25em
      display: flex
      opacity: 0.6
    &:disabled
      cursor: not-allowed
      i.format
        opacity: 0.2
    &.spaced
      margin-right: 2px

  & .divider
    width: 1px
    background-color: #eee
    margin: 0 4px

  & select.toolbar-item
    border: 0
    display: flex
    background: none
    border-radius: 10px
    padding: 8px
    vertical-align: middle
    -webkit-appearance: none
    -moz-appearance: none
    width: 70px
    font-size: 14px
    color: #777
    text-overflow: ellipsis

  & select.code-language
    text-transform: capitalize
    width: 130px


  & .toolbar-item
    .text
      display: flex
      line-height: 20px
      vertical-align: middle
      font-size: 14px
      color: #777
      text-overflow: ellipsis
      width: 70px
      overflow: hidden
      height: 20px
      text-align: left
    .icon
      display: flex
      width: 20px
      height: 20px
      user-select: none
      margin-right: 8px
      line-height: 16px
      background-size: contain

  & img.chevron-down
    margin-top: 3px
    width: 16px
    height: 16px
    display: flex
    user-select: none
    &.inside
      width: 16px
      height: 16px
      display: flex
      margin-left: -25px
      margin-top: 11px
      margin-right: 10px
      pointer-events: none


img.chevron-down
  background-color: transparent
  background-size: contain
  display: inline-block
  height: 8px
  width: 8px


#block-controls
  button
    &:hover
      background-color: #efefef
    &:focus-visible
      border-color: blue
  span
    &.block-type
      background-size: contain
      display: block
      width: 18px
      height: 18px
      margin: 2px
    &.paragraph
      background-image: url(/assets/textEditor/text-paragraph.svg)
    &.h1
      background-image: url(/assets/textEditor/type-h1.svg)
    &.h2
      background-image: url(/assets/textEditor/type-h2.svg)
    &.quote
      background-image: url(/assets/textEditor/chat-square-quote.svg)
    &.ul
      background-image: url(/assets/textEditor/list-ul.svg)
    &.ol
      background-image: url(/assets/textEditor/list-ol.svg)
    &.code
      background-image: url(/assets/textEditor/code.svg)




.dropdown
  z-index: 5
  display: block
  position: absolute
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)
  border-radius: 8px
  min-width: 100px
  min-height: 40px
  background-color: #fff
  .item
    margin: 0 8px 0 8px
    padding: 8px
    color: #050505
    cursor: pointer
    line-height: 16px
    font-size: 15px
    display: flex
    align-content: center
    flex-direction: row
    flex-shrink: 0
    justify-content: space-between
    background-color: #fff
    border-radius: 8px
    border: 0
    min-width: 268px
    &:hover
      background-color: #eee
    &:first-child
      margin-top: 8px
    &:last-child
      margin-bottom: 8px
    .active
      display: flex
      width: 20px
      height: 20px
      background-size: contain
    .text
      display: flex
      line-height: 20px
      flex-grow: 1
      width: 200px
    .icon
      display: flex
      width: 20px
      height: 20px
      user-select: none
      margin-right: 12px
      line-height: 16px
      background-size: contain


.link-editor
  position: absolute
  z-index: 100
  top: -10000px
  left: -10000px
  margin-top: -6px
  max-width: 300px
  width: 100%
  opacity: 0
  background-color: #fff
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3)
  border-radius: 8px
  transition: opacity 0.5s
  .link-input
    display: block
    width: calc(100% - 24px)
    box-sizing: border-box
    margin: 8px 12px
    padding: 8px 12px
    border-radius: 15px
    background-color: #eee
    font-size: 15px
    color: rgb(5, 5, 5)
    border: 0
    outline: 0
    position: relative
    font-family: inherit
    a
      color: rgb(33, 111, 219)
      text-decoration: none
      display: block
      white-space: nowrap
      overflow: hidden
      margin-right: 30px
      text-overflow: ellipsis
      &:hover
        text-decoration: underline
  div.link-edit
    background-image: url(/assets/textEditor/pencil-fill.svg)
    background-size: 16px
    background-position: center
    background-repeat: no-repeat
    width: 35px
    vertical-align: -0.25em
    position: absolute
    right: 0
    top: 0
    bottom: 0
    cursor: pointer
  .button
    width: 20px
    height: 20px
    display: inline-block
    padding: 6px
    border-radius: 8px
    cursor: pointer
    margin: 0 2px
    &.hovered
      width: 20px
      height: 20px
      display: inline-block
      background-color: #eee


  & .button i, .actions i
    background-size: contain
    display: inline-block
    height: 20px
    width: 20px
    vertical-align: -0.25em


i.undo
  background-image: url(/assets/textEditor/arrow-counterclockwise.svg)


i.redo
  background-image: url(/assets/textEditor/arrow-clockwise.svg)


.icon.paragraph
  background-image: url(/assets/textEditor/text-paragraph.svg)


.icon.large-heading, .icon.h1
  background-image: url(/assets/textEditor/type-h1.svg)

.icon.small-heading, .icon.h2
  background-image: url(/assets/textEditor/type-h2.svg)


.icon.bullet-list, .icon.ul
  background-image: url(/assets/textEditor/list-ul.svg)


.icon.numbered-list, .icon.ol
  background-image: url(/assets/textEditor/list-ol.svg)


.icon.quote
  background-image: url(/assets/textEditor/chat-square-quote.svg)


.icon.code
  background-image: url(/assets/textEditor/code.svg)


.icon.font-color
  background-image: url(/assets/textEditor/font-color-svgrepo-com.svg)


.icon.bg-color
  background-image: url(/assets/textEditor/background-color-svgrepo-com.svg)


i.bold
  background-image: url(/assets/textEditor/type-bold.svg)


i.italic
  background-image: url(/assets/textEditor/type-italic.svg)


i.underline
  background-image: url(/assets/textEditor/type-underline.svg)


i.strikethrough
  background-image: url(/assets/textEditor/type-strikethrough.svg)


i.code
  background-image: url(/assets/textEditor/code.svg)


i.font-color
  background-image: url(/assets/textEditor/code.svg)


i.link
  background-image: url(/assets/textEditor/link.svg)


i.left-align
  background-image: url(/assets/textEditor/text-left.svg)


i.center-align
  background-image: url(/assets/textEditor/text-center.svg)


i.right-align
  background-image: url(/assets/textEditor/text-right.svg)


i.justify-align
  background-image: url(/assets/textEditor/justify.svg)

i.input
  background-image: url(/assets/textEditor/input.png)

i.match
  background-image: url(/assets/textEditor/row.png)


.PlaygroundEditorTheme__tableScrollableWrapper
  overflow-x: auto
  margin: 0px 25px 30px 0px

.PlaygroundEditorTheme__tableScrollableWrapper > .PlaygroundEditorTheme__table
  /* Remove the table's vertical margin and put it on the wrapper */
  margin-top: 0
  margin-bottom: 0

.PlaygroundEditorTheme__tableAlignmentCenter
  margin-left: auto
  margin-right: auto

.PlaygroundEditorTheme__tableAlignmentRight
  margin-left: auto

.PlaygroundEditorTheme__table
  border-collapse: collapse
  border-spacing: 0
  overflow-y: scroll
  overflow-x: scroll
  table-layout: fixed
  width: fit-content
  margin-top: 25px
  margin-bottom: 30px

.PlaygroundEditorTheme__tableFrozenRow
  /* position:sticky needs overflow:clip or visible
     https://github.com/w3c/csswg-drafts/issues/865#issuecomment-350585274 */
  overflow-x: clip

.PlaygroundEditorTheme__tableFrozenRow tr:nth-of-type(1) > td
  overflow: clip
  background-color: #ffffff
  position: sticky
  z-index: 2
  top: 44px

.PlaygroundEditorTheme__tableFrozenRow tr:nth-of-type(1) > th
  overflow: clip
  background-color: #f2f3f5
  position: sticky
  z-index: 2
  top: 44px

.PlaygroundEditorTheme__tableFrozenRow tr:nth-of-type(1) > th:after,
.PlaygroundEditorTheme__tableFrozenRow tr:nth-of-type(1) > td:after
  content: ''
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  border-bottom: 1px solid #bbb

.PlaygroundEditorTheme__tableFrozenColumn tr > td:first-child
  background-color: #ffffff
  position: sticky
  z-index: 2
  left: 0

.PlaygroundEditorTheme__tableFrozenColumn tr > th:first-child
  background-color: #f2f3f5
  position: sticky
  z-index: 2
  left: 0

.PlaygroundEditorTheme__tableFrozenColumn tr > :first-child::after
  content: ''
  position: absolute
  left: 0
  top: 0
  right: 0
  height: 100%
  border-right: 1px solid #bbb

.PlaygroundEditorTheme__tableRowStriping tr:nth-child(even)
  background-color: #f2f5fb

.PlaygroundEditorTheme__tableSelection *::selection
  background-color: transparent

.PlaygroundEditorTheme__tableSelected
  outline: 2px solid rgb(60, 132, 244)

.PlaygroundEditorTheme__tableCell
  border: 1px solid #bbb
  width: 75px
  vertical-align: top
  text-align: start
  padding: 6px 8px
  position: relative
  outline: none
  overflow: auto


.PlaygroundEditorTheme__tableCell > *
  overflow: inherit

.PlaygroundEditorTheme__tableCellResizer
  position: absolute
  right: -4px
  height: 100%
  width: 8px
  cursor: ew-resize
  z-index: 10
  top: 0

.PlaygroundEditorTheme__tableCellHeader
  background-color: #f2f3f5
  text-align: start

.PlaygroundEditorTheme__tableCellSelected
  caret-color: transparent

.PlaygroundEditorTheme__tableCellSelected::after
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  background-color: highlight
  mix-blend-mode: multiply
  content: ''
  pointer-events: none

.PlaygroundEditorTheme__tableAddColumns
  position: absolute
  background-color: #eee
  height: 100%
  animation: table-controls 0.2s ease
  border: 0
  cursor: pointer

.PlaygroundEditorTheme__tableAddColumns:after
  background-image: url(/assets/textEditor/plus.svg)
  background-size: contain
  background-position: center
  background-repeat: no-repeat
  display: block
  content: ' '
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0.4

.PlaygroundEditorTheme__tableAddColumns:hover,
.PlaygroundEditorTheme__tableAddRows:hover
  background-color: #c9dbf0

.PlaygroundEditorTheme__tableAddRows
  position: absolute
  width: calc(100% - 25px)
  background-color: #eee
  animation: table-controls 0.2s ease
  border: 0
  cursor: pointer

.PlaygroundEditorTheme__tableAddRows:after
  background-image: url(/assets/textEditor/plus.svg)
  background-size: contain
  background-position: center
  background-repeat: no-repeat
  display: block
  content: ' '
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0.4

@keyframes table-controls
  0% 
    opacity: 0

  100%
    opacity: 1


.PlaygroundEditorTheme__tableCellResizeRuler
  display: block
  position: absolute
  width: 1px
  background-color: rgb(60, 132, 244)
  height: 100%
  top: 0

.PlaygroundEditorTheme__tableCellActionButtonContainer
  display: block
  right: 5px
  top: 6px
  position: absolute
  z-index: 4
  width: 20px
  height: 20px

.PlaygroundEditorTheme__tableCellActionButton
  background-color: #eee
  display: block
  border: 0
  border-radius: 20px
  width: 20px
  height: 20px
  color: #222
  cursor: pointer

.PlaygroundEditorTheme__tableCellActionButton:hover
  background-color: #ddd


@keyframes table-controls
  0%
    opacity: 0

  100%
    opacity: 1


.ExampleEditorTheme__tableCellResizeRuler
  display: block
  position: absolute
  width: 1px
  background-color: rgb(60, 132, 244)
  height: 100%
  top: 0

.ExampleEditorTheme__tableCellActionButtonContainer
  display: block
  right: 5px
  top: 6px
  position: absolute
  z-index: 4
  width: 20px
  height: 20px

.ExampleEditorTheme__tableCellActionButton
  background-color: #eee
  display: block
  border: 0
  border-radius: 20px
  width: 20px
  height: 20px
  color: #222
  cursor: pointer

.ExampleEditorTheme__tableCellActionButton:hover
  background-color: #ddd

