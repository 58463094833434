@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap')

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: "Open sans", sans-serif

a
  text-decoration: none

html
  font-size: 10px
  scroll-behavior: smooth