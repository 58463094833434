@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"

.radioLabel
  color: #303030
  margin-right: 7px
  -webkit-tap-highlight-color: transparent
  cursor: pointer
  margin-top: 10px
  display: flex
  align-items: center
  justify-content: center
  font-size: 1.8rem
  font-weight: 600



.radioInput
  margin: 0
  visibility: hidden
  display: none

.wrapper
  width: 2rem
  display: flex
  align-items: center
  justify-content: center
  @media only screen and (max-width: 768px)
    width: 10%
  .customRadio
    cursor: pointer
    width: 20px
    height: 20px
    border: 2px solid #9a9a9a
    border-radius: 50%
    display: inline-block
    position: relative
    &.active
      border: 2px solid $color-main-light
      &:after
        opacity: 1 !important
        transform: translate(-50%, -50%) scale(1) !important
    &:after
      content: ''
      width: 10px
      height: 10px
      background: $color-main-light
      position: absolute
      border-radius: 50%
      top: 50%
      left: 50%
      transform: translate(-50%, -50%) scale(0)
      opacity: 0
      transition: all .1s




.text
  margin-left: 1rem
  max-width: 90%



