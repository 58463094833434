@import "styles/abstracts/variables"

.lesson
  width: 100%
  pre
    background-color: #f6f6f6
    padding: 1rem
    font-size: 1.8rem
  .header
    margin-bottom: 3rem
    h1
      font-size: 3rem
      color: $color-grey-dark
  .container
    .video
      width: 100%
  .footer
    padding-top: 3rem
    border-top: 2px solid $color-grey-light
    margin-top: 1rem

.stars
  margin-top: 1.5rem
  i
    margin: 0 .4rem
    font-size: 2.4rem
  .active
    color: #ffc62e



