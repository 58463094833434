@import "styles/abstracts/variables"
@import "styles/abstracts/mixins"


.createCode
  width: 60rem
  padding: 2rem
  border: 2px solid $color-grey-dark
  border-radius: 5px
  margin: 2rem 0
  .subHeader
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 1rem
    .trash
      font-size: 1.7rem
      color: red
      cursor: pointer
      @include btn-active
  &__header
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    textarea
      width: 100%
      height: 13rem
      resize: none
      padding: 1rem
      margin-bottom: 1rem
  &__container
    margin-top: 1rem
    .btn
      width: fit-content
      height: fit-content
      padding: .5rem 1rem
      border: 1px solid $color-grey-dark
      font-size: 1.5rem
      margin-top: 2rem
      cursor: pointer
      transition: all .1s
      user-select: none
      @include btn-active
      &:hover
        background-color: $color-grey-dark
        color: white


.viewText
  margin-top: 1rem
  width: 100%
  .code
    max-width: 100%
    position: relative
    background: rgb(43, 43, 43)
    padding: 2rem
    border-radius: 5px
    font-size: 1.7rem
    .copyBtn
      position: absolute
      top: 1rem
      right: 1rem
      i
        font-size: 2.5rem
        color: white
  .popup
    //visibility: hidden
    //opacity: 0
    transition-delay: 1s
    top: -10px
    right: -25px
    position: absolute
    transition: all .1s
    i
      font-size: 1.7rem



@media only screen and (max-width: 425px)
  .viewText
    margin-top: 1rem
    width: 100%
    .matchedWord
      padding: 0.5rem 1rem
      border: 1px solid $color-grey-light
      width: fit-content
      height: fit-content
      font-size: 1.8rem
      touch-action: none
      margin: .3rem
      cursor: grab
      &:active
        cursor: grabbing
      &__preview
        border: 1px solid
        font-size: 1.6rem
      &__box
        display: inline-flex
        align-items: center
        justify-content: center
        min-width: 10rem
        min-height: 3rem
        max-width: fit-content
        max-height: fit-content
        background-color: $color-grey-lighter
        padding: 0.2rem
        margin: 0.2rem
        border-radius: 5px
        .matchedWord
          border: none
        &.active
          border: 2px solid $color-success !important
        &.error
          border: 2px solid $color-danger !important
    .text
      position: relative
      width: fit-content
      margin-bottom: 1rem
      p
        white-space: pre-line
        font-size: 1.8rem
      .popup
        //visibility: hidden
        //opacity: 0
        transition-delay: 1s
        top: -10px
        right: -25px
        position: absolute
        transition: all .1s
        i
          font-size: 1.7rem
    span
      font-size: 1.8rem
    input
      min-width: 4rem
      margin: .4rem 0
      font-size: 1.6rem
      padding: .3rem
      border-radius: 5px
      &.active
        border: 2px solid $color-success !important
      &.error
        border: 2px solid $color-danger !important